import React from "react";
import { Button, Container, Row } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";

//assets
import img from "../assets/images/organization-header2.jpg";
import logoRMSWhite from "../assets/images/logo-rms-white.svg";

// icons
import { FaLongArrowAltRight } from "react-icons/fa";

//translate
import { useTranslation } from "react-i18next";

const HeaderOrganization = () => {
  const { t } = useTranslation();

  return (
    <section>
      <header className="organization-header">
        <img src={img} alt="" className="img-header-organization"></img>
        <div className="viewport-organization">
          <Container>
            <Row
              style={{
                maxWidth: "80%",
                marginRight: "auto",
                marginLeft: "auto",
              }}
              className="wow animate__animated animate__fadeIn"
              data-wow-duration="1s"
            >
              <div className="text-white text-center ">
                <img
                  src={logoRMSWhite}
                  alt=""
                  style={{ width: "150px", marginTop: "40px" }}
                ></img>

                <h2 className="fw-bold mt-5 wow animate__animated animate__fadeIn">
                  {t("title_organization_header")}
                </h2>
                <br />

                <h5>{t("detail_organization_header")}</h5>

                <AnchorLink href="#komisioner" className="btn-header-organization">
                  <Button className="mt-4 outline-white px-4 py-2">
                    {t("btn_commissioner_header")}{" "}
                    <FaLongArrowAltRight
                      className="ms-3"
                      style={{ marginTop: "-3px" }}
                    />
                  </Button>
                </AnchorLink>
                <AnchorLink href="#direksi" className="btn-header-organization">
                  <Button className="mt-4 outline-white px-4 py-2">
                    {t("btn_direction_header")}{" "}
                    <FaLongArrowAltRight
                      className="ms-3"
                      style={{ marginTop: "-3px" }}
                    />
                  </Button>
                </AnchorLink>
                <AnchorLink
                  href="#organization"
                  className="btn-header-organization"
                >
                  <Button className="mt-4 outline-white px-4 py-2">
                    {t("btn_organization_header")}{" "}
                    <FaLongArrowAltRight
                      className="ms-3"
                      style={{ marginTop: "-3px" }}
                    />
                  </Button>
                </AnchorLink>
              </div>
            </Row>
          </Container>
        </div>{" "}
      </header>
    </section>
  );
};

export default HeaderOrganization;
