//core team
import coreTeam from "../assets/images/organization/core_team/CoreTeam-full.jpg";
// import team1 from "../assets/images/organization/core_team/rms-dwi-hari widodo(general-manager).png";
// import team2 from "../assets/images/organization/core_team/rms-reza-pahlawan(head-sales1).png";
// import team3 from "../assets/images/organization/core_team/rms-dennis-stephen(head-sales2).png";
// import team4 from "../assets/images/organization/core_team/rms-hudaesy-agam(head-sales3).png";
// import team5 from "../assets/images/organization/core_team/rms-doddy-novriyanto(head-business).png";
// import team6 from "../assets/images/organization/core_team/rms-icuk-budiarto-biga(head-of-transformer).png";
// import team7 from "../assets/images/organization/core_team/rms-franky-hilarius-s(head-of-expansion).png";

//finance
import financeTeam from "../assets/images/organization/finance/FinanceTeam-full.jpg";
// import finance1 from "../assets/images/organization/finance/Sherly Steivvanie-Head of Finance.png";
// import finance2 from "../assets/images/organization/finance/Enyk Sutarti-Finance Manager.png";
// import finance3 from "../assets/images/organization/finance/Like Indrawati-Finance Manager.png";

export const __COTeam = [
  {
    name: "Core Team",
    img: coreTeam

  }
  // {
  //   name: "Dwi Hari Widodo",
  //   job: "General Manager",
  //   img: team1,
  // },
  // {
  //   name: "Reza Pahlawan",
  //   job: "Head of Sales 1",
  //   img: team2,
  // },
  // {
  //   name: "Dennis Stephen T",
  //   job: "Head of Sales 2",
  //   img: team3,
  // },
  // {
  //   name: "Hudaesy Agam S",
  //   job: "Head of Sales 3",
  //   img: team4,
  // },
  // {
  //   name: "Doddy Novriyanto",
  //   job: "Head of Business Development",
  //   img: team5,
  // },
  // {
  //   name: "Icuk Budiarto Biga",
  //   job: "Head of Transformation",
  //   img: team6,
  // },
  // {
  //   name: "Franky Hilarius S",
  //   job: "Head of Expansion",
  //   img: team7,
  // },
];

export const __Finance = [
  {
    name: "Finance Team",
    img: financeTeam
  }
  // {
  //   name: "Sherly Steivvanie",
  //   job: "Head of Finance",
  //   img: finance1,
  // },
  // {
  //   name: "Enyk Sutarti",
  //   job: "Finance Manager",
  //   img: finance2,
  // },
  // {
  //   name: "Like Indrawati",
  //   job: "Finance Manager",
  //   img: finance3,
  // },
];
