import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { month, month_id } from "./static/months";

const current = new Date();

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
  resources: {
    en: {
      translation: {
        //navbar
        home: "Home",
        aboutUs: "About Us",
        portfolio: "Portfolio",
        organization: "Organization",
        branch: "Branch",
        contactUs: "Contact Us",
        blogs: "Blogs",
        career: "Career",
        news: "News",

        //
        banner: "Read our latest News",
        click_here: "Click here",

        //video header
        video_title:
          "We’re RMS, leading the distribution network in Indonesia by combine the technology inside the channel",
        btn_about_us: "ABOUT US",

        //who we are
        title_who_we_are: "Who We Are",
        detail_who_we_are_bold:
          "Distributions Company with more than 10 Years experience to Handling FMCG Supply Chain.",
        detail_who_we_are:
          "RMS cooperates and maintains strong relationships with large companies (principals) to distribute products to consumers. RMS actively develops its business by continuing to expand to various regions throughout Indonesia. As of this moment RMS exists with 80 branches and will continue to expand.",
        join_who_we_are: "Wait for us in your city!",

        //rms story
        title_rms_story: "OUR STORY",

        //rms network
        title_rms_network: "RMS Network",
        office_rms_network: "Office",
        warehouse_rms_network: "Branch",
        employees_rms_network: "Employees",

        //overview
        title_overview: "Overview",
        branch_overview: "Branch",
        retail_store_overview: "Employees",
        seller_overview: "Whole Seller",
        date_overview: `By ${
          month[current.getMonth()]
        } ${current.getFullYear()}`,

        //why choose us
        title_why_choose_us: "Why Choose Us?",
        desc_why_choose_us:
          "We are committed to maintaining consumer and principal trust...",

        //collaborate
        desc_collaborate:
          "Rukun Mitra Sejati, trusted and profitable business.",
        btn_collaborate: "Collaborate Now",

        //rms news
        title_rms_news: "RMS News",
        more_rms_news: "Read More",
        nc_2024: {
          title: "NATIONAL CONVENTION 2024",
          description:
            "RMS Annual Event to discuss the annual business performance attended by representatives of leaders from all branches across Indonesia",
          btn: "Read More",
          paragraph1:
            "The RMS National Convention 2024 is an annual agenda attended by representatives of leaders from all over Indonesia, gathering in a national forum, and attended by the board of directors and top management to discuss the business performance for the past year and annual business strategy planning.",
          paragraph2:
            "This convention is an agenda that brings together all teams from RMS. The convention is attended directly by Director Lie Hendy Lianto (Xiong), COO Herry Ruslim, Dwi Hari Widodo as General Manager, and Eric Onggowarsito as CFO. In addition, the convention is also attended by Legal, HRD, Finance, Tax, Accounting, and Operation divisions. This convention facilitates and opens a space for discussion among branch teams, directors, and management to achieve better performance.",
          paragraph3:
            "The convention is carried out with enthusiasm and a concept of the event",
          tagline: "Collaboration, Level up",
          paragraph3cont:
            "The meaning is not just collaboration, but how to bring that collaboration to bring synergy and benefits to RMS business processes. By emphasizing improved collaboration, this event conveys a message on how to synergize and collaborate better with fellow divisions or between divisions for a better future.",
        },

        //contact us
        btn_contact_us: "Contact Us",

        //vision
        title_vision: "Vision",

        //mission
        title_mission: "Mission",

        //core values
        title_core_values: "Core Values",

        //history
        title_history: "Company History",

        //partner
        title_partner: "Our Partners",
        desc_partner: "BIG BRANDS ALSO WORK TOGETHER",
        amount_partner: "More than",
        detail_partner:
          "products from major brands are distributed by RMS every day. By applying information technology, RMS can easily reach the regions in Indonesia. RMS is committed to maintaining strong communication and relationships with stakeholders and becoming a mutually beneficial long-term business partner.",
        title_principal_partner: "Principal",
        title_strategic_partner: "Strategic Partner",

        //testimoni
        title_testimonial: "Customer is Our Top Priority",

        //achievement
        title_achievement: "PT Rukun Mitra Sejati Awards",

        //organization
        title_organization_header: "Organization PT Rukun Mitra Sejati",
        detail_organization_header: "",
        // "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer",

        //Komisaris
        btn_commissioner_header: "BOARD OF COMMISSIONER",
        title_commissioner: "BOARD OF COMMISSIONER",

        btn_direction_header: "BOARD OF DIRECTOR",
        btn_organization_header: "ORGANIZATION STRUCTURE",

        //direction
        title_direction: "BOARD OF DIRECTOR",
        title_education_story: "Education History",
        title_career_story: "Career History",

        //organization
        title_organization: "Organization Structure",
        title_core_team: "CORE TEAM",
        title_finance_team: "FINANCE TEAM",

        //contact-us
        title_rms_social_media: "Stay Connected With Us",
        rms_social_media: "RMS Social Media",

        //career
        title_career_header: "Career PT Rukun Mitra Sejati",
        btn_career_header: "OUR JOB VACANCIES & INFORMATIONS...",
        detail_career_header: "",
        // "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer",
        btn_job_vacancies: "Job Vacancies",
        btn_intern: "Academy and Internships",
        desc_job_vacancy: "",
        // "Job Vacancies..... Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
        desc_intern_vacancy: "",
        // "Internship Vacancies..... Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
        placeholder_search: "Search",
        btn_search: "Search",
      },
    },
    id: {
      translation: {
        //navbar
        home: "Beranda",
        aboutUs: "Tentang Kami",
        portfolio: "Portofolio",
        organization: "Organisasi",
        branch: "Cabang",
        contactUs: "Hubungi Kami",
        blogs: "Blogs",
        career: "Karir",
        news: "Berita",

        // Announcement Banner
        banner: "Baca berita terbaru kami",
        click_here: "Klik disini",

        //video header
        btn_about_us: "TENTANG KAMI",

        //who we are
        title_who_we_are: "Siapa Kami",
        detail_who_we_are_bold:
          "Perusahaan Distribusi dengan pengalaman lebih dari 10 tahun dalam menangani rantai pasokan FMCG.",
        detail_who_we_are:
          "RMS bekerjasama dan menjaga hubungan yg kuat dengan perusahaan-perusahaan besar (principal) untuk mendistribusikan produk hingga sampai kepada konsumen. RMS aktif mengembangkan bisnisnya dengan terus berekspansi melebarkan sayap ke berbagai daerah di seluruh Indonesia. Sampai detik ini RMS exist dengan 80 cabang dan akan terus berekpansi.",
        join_who_we_are: "Tunggu kami di kota anda!",

        //rms story
        title_rms_story: "CERITA KAMI",

        //rms network
        title_rms_network: "Jaringan RMS",
        office_rms_network: "Kantor",
        warehouse_rms_network: "Cabang",
        employees_rms_network: "Karyawan",

        //overview
        title_overview: "Gambaran Umum",
        branch_overview: "Cabang",
        retail_store_overview: "Karyawan",
        seller_overview: "Penjual",
        date_overview: `Per ${month_id[current.getMonth()]} ${
          current.getFullYear()
        }`,

        //why choose us
        title_why_choose_us: "Kenapa Memilih Kami?",
        desc_why_choose_us:
          "Kami berkomitmen untuk menjaga kepercayaan konsumen dan principal…",

        //collaborate
        desc_collaborate:
          "Rukun Mitra Sejati, bisnis yang terpercaya dan menguntungkan.",
        btn_collaborate: "Kerja Sama Sekarang",

        //rms news
        title_rms_news: "Berita RMS",
        more_rms_news: "Baca Selengkapnya",
        nc_2024: {
          title: "KONVENSI NASIONAL 2024",
          description:
            "Event Tahunan RMS dalam rangka membahas kinerja bisnis tahunan yang dihadiri oleh perwakilan leader di seluruh cabang di Indonesia",
          btn: "Read More",
          paragraph1:
            "Konvensi Nasional RMS 2024 adalah agenda tahunan RMS yang dihadiri oleh perwakilan leader di seluruh Indonesia, yang berkumpul di suatu forum nasional, serta dihadiri oleh direksi dan top manajemen untuk membahas kinerja bisnis selama satu tahun dan perencanaan strategi bisnis tahunan",
          paragraph2:
            "Konvensi ini merupakan agenda yang mempertemukan segala lini tim dari RMS. Konvensi ini dihadiri langsung oleh direktur Lie Hendy Lianto (Xiong), COO Herry Ruslim, Dwi Hari Widodo sebagai General Manager, dan Eric Onggowarsito sebagai CFO. Selain itu, Konvensi ini juga dihadiri oleh divisi Legal, HRD, Finance, Tax, Accounting, dan Operation. Konvensi ini memfasilitasi dan membuka ruang diskusi antara tim cabang, direksi, dan manajemen untuk mencapai kinerja yang lebih baik.",
          paragraph3:
            "Konvensi ini dilaksanakan dengan meriah dengan konsep acara",
          tagline: "Collaboration, Level up",
          paragraph3cont:
            "Maksudnya tidak hanya kolaborasi semata, namun bagaimana membawa kolaborasi tersebut membawa sinergi dan kebermanfaatan bagi proses bisnis RMS. Dengan mengedepankan peningkatan kolaborasi, acara ini memiliki pesan bagaimana caranya untuk bersinergi dan bekerjasama lebih baik lagi dengan sesama divisi ataupun antar divisi agar dapat lebih baik dalam tahun kedepan",
        },

        //contact us
        btn_contact_us: "Hubungi Kami",

        //vision
        title_vision: "Visi",

        //mission
        title_mission: "Misi",

        //core values
        title_core_values: "Nilai-Nilai Utama",

        //history
        title_history: "Sejarah Perusahaan",

        //partner
        title_partner: "Mitra Kami",
        desc_partner: "BRAND BESAR JUGA BEKERJA SAMA",
        amount_partner: "Lebih dari",
        detail_partner:
          "produk dari brand-brand besar didistribusikan oleh RMS setiap harinya. Dengan menerapkan teknologi informasi, RMS dapat dengan mudah menjangkau daerah-daerah di Indonesia. RMS berkomitme menjaga komunikasi dan hubungan yang kuat dengan para pemangku kepentingan dan menjadi mitra bisnis jangka panjang yang saling menguntungkan.",
        title_principal_partner: "Mitra Utama",
        title_strategic_partner: "Mitra Strategis",

        //testimoni
        title_testimonial: "Pelanggan adalah Prioritas Utama Kami",

        //achievement
        title_achievement: "Penghargaan PT Rukun Mitra Sejati",

        //organization
        title_organization_header: "Organisasi PT Rukun Mitra Sejati",
        detail_organization_header: "",
        btn_direction_header: "DEWAN DIREKSI",
        btn_organization_header: "STRUKTUR ORGANISASI",

        //Komisaris
        btn_commissioner_header: "DEWAN KOMISARIS",
        title_commissioner: "DEWAN KOMISARIS",

        //direction
        title_direction: "DEWAN DIREKSI",
        title_education_story: "Riwayat Pendidikan",
        title_career_story: "Riwayat Karir",

        //organization
        title_organization: "Struktur Organisasi",
        title_core_team: "TIM INTI",
        title_finance_team: "TIM KEUANGAN",

        //contact-us
        title_rms_social_media: "Tetap Terhubung Dengan Kami",
        rms_social_media: "Media Sosial RMS",

        //career
        title_career_header: "Karir PT Rukun Mitra Sejati",
        btn_career_header: "LOWONGAN DAN INFORMASI KAMI...",
        detail_career_header: "",
        // "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer",
        btn_job_vacancies: "Lowongan Kerja",
        btn_intern: "Akademi dan Magang",
        desc_job_vacancy: "",
        // "Lowongan Kerja..... Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
        desc_intern_vacancy: "",
        // "Magang..... Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
        placeholder_search: "Cari",
        btn_search: "Cari",
      },
    },
  },
});

export default i18n;
